import type { Context } from 'react';

import { MintServiceLocator } from '@mint-lib/routing-context';

import type { MintAdminServiceLocatorType } from '../types.js';

export const MintAdminServiceLocator =
  MintServiceLocator as Context<MintAdminServiceLocatorType | null>;

export { FlagsmithProvider, AuthProvider } from '@mint-lib/routing-context';
