import {
  createApi,
  requestDataToSnakeCase,
  responseDataToCamelCase,
  responseErrorDataToCamelCase,
  type AxiosError,
  type AxiosInstance,
  type BaseAuthServiceInterface,
  type CreateAxiosDefaults,
  type InternalAxiosRequestConfig,
} from '@mint-lib/api';

import { HttpStatus } from '../../../api/src/HttpStatus.js';
import type { MintAdminServiceLocatorAbstractFactoryContext } from '../types.js';

/**
 * Builds an axios instances with the following types:
 * - guestApi: no authentication
 * - accessApi: uses access token for authentication (used only for requesting workspace access tokens)
 * - defaultApi: uses access token for a workspace
 */
export async function httpFactory(
  ctx: MintAdminServiceLocatorAbstractFactoryContext,
  type: 'guestApi' | 'defaultApi' | (string & {}),
  config?: CreateAxiosDefaults,
): Promise<AxiosInstance> {
  const client = createApi({
    baseURL: '/',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  client.interceptors.request.use(requestDataToSnakeCase, Promise.reject);
  if (type === 'guestApi') {
    client.interceptors.response.use(
      responseDataToCamelCase,
      responseErrorDataToCamelCase,
    );
  }
  if (['defaultApi'].includes(type)) {
    const auth = await ctx.getInstance('auth');
    client.interceptors.response.use(
      responseDataToCamelCase,
      buildErrorInterceptor(auth),
    );
    client.interceptors.request.use(
      buildAccessTokenMiddleware(auth),
      Promise.reject,
    );

    ctx.on('auth', 'auth', ctx.invalidate);
    ctx.on('auth', 'logout', () => ctx.invalidate());
  }
  return client;
}

function buildErrorInterceptor(auth: BaseAuthServiceInterface) {
  return function (err: AxiosError) {
    // eslint-disable-next-line no-console

    if (err.response?.status === HttpStatus.Unauthorized) {
      auth?.logout(err);
    }
    return responseErrorDataToCamelCase(err);
  };
}

function buildAccessTokenMiddleware(
  auth: BaseAuthServiceInterface,
  isNotWorkspace = false,
) {
  return async (config: InternalAxiosRequestConfig) => {
    if (import.meta.env.VITEST_WORKER_ID || auth === null) {
      return config;
    }
    // @ts-ignore TODO: X-Session-ID is not in the AxiosRequestConfig type, remove it when proper login will be added
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: await auth.provideBearerToken(),
        'X-Session-ID': await auth.provideBearerToken(),
      },
    } as InternalAxiosRequestConfig;
  };
}
