import type { BaseAuthStorage } from '@mint-lib/auth';

export enum AuthStorageKeys {
  ACCESS_TOKEN = 'admin_access_token',
  REFRESH_TOKEN = 'admin_refresh_token',
  ACCESS_TOKEN_EXPIRES_IN = 'admin_access_token_expires_in',
  REFRESH_TOKEN_EXPIRES_IN = 'admin_refresh_token_expires_in',
}

export const temporaryAdminBaseStorage: Partial<BaseAuthStorage> = {
  getAccessToken() {
    return sessionStorage.getItem(AuthStorageKeys.ACCESS_TOKEN);
  },
  setAccessToken(token: string | null) {
    if (token === null) {
      sessionStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
      return;
    }
    sessionStorage.setItem(AuthStorageKeys.ACCESS_TOKEN, token);
  },
  getAccessTokenExpiresIn() {
    const expiresIn = sessionStorage.getItem(
      AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN,
    );
    return expiresIn ? parseInt(expiresIn, 10) : 0;
  },
  setAccessTokenExpiresIn(expiresIn: number | null) {
    if (expiresIn === null) {
      sessionStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN);
      return;
    }
    sessionStorage.setItem(
      AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN,
      expiresIn.toString(),
    );
  },
};

export const adminBaseStorage: BaseAuthStorage = {
  getAccessToken() {
    return localStorage.getItem(AuthStorageKeys.ACCESS_TOKEN);
  },
  setAccessToken(token: string | null) {
    if (token === null) {
      localStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN);
      return;
    }
    localStorage.setItem(AuthStorageKeys.ACCESS_TOKEN, token);
  },
  getAccessTokenExpiresIn() {
    const expiresIn = localStorage.getItem(
      AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN,
    );
    return expiresIn ? parseInt(expiresIn, 10) : 0;
  },
  setAccessTokenExpiresIn(expiresIn: number | null) {
    if (expiresIn === null) {
      localStorage.removeItem(AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN);
      return;
    }
    localStorage.setItem(
      AuthStorageKeys.ACCESS_TOKEN_EXPIRES_IN,
      expiresIn.toString(),
    );
  },
  getRefreshToken() {
    return localStorage.getItem(AuthStorageKeys.REFRESH_TOKEN);
  },
  setRefreshToken(token: string | null) {
    if (token === null) {
      localStorage.removeItem(AuthStorageKeys.REFRESH_TOKEN);
      return;
    }
    localStorage.setItem(AuthStorageKeys.REFRESH_TOKEN, token);
  },
  getRefreshTokenExpiresIn() {
    const expiresIn = localStorage.getItem(
      AuthStorageKeys.REFRESH_TOKEN_EXPIRES_IN,
    );
    return expiresIn ? parseInt(expiresIn, 10) : 0;
  },
  setRefreshTokenExpiresIn(expiresIn: number | null) {
    if (expiresIn === null) {
      localStorage.removeItem(AuthStorageKeys.REFRESH_TOKEN_EXPIRES_IN);
      return;
    }
    localStorage.setItem(
      AuthStorageKeys.REFRESH_TOKEN_EXPIRES_IN,
      expiresIn.toString(),
    );
  },
};
