import { createRouter, FileRoutesByPath, Router } from '@tanstack/react-router';

import { makeRoutingContext } from '@mint-lib/admin-routing-context';
import { NotFoundErrorPage } from '@mint-lib/error-boundary';

// import './mint-env.js';

// @ts-ignore TS6307 Does not include this file
import { routeTree } from './routeTree.gen.js';

export * from '@tanstack/react-router';

export const routerContext = makeRoutingContext();
export type MintRouter = Router<typeof routeTree, 'preserve'>;

// Create a new router instance
const routerInstance: MintRouter = createRouter({
  routeTree,
  context: routerContext,
  defaultNotFoundComponent: NotFoundErrorPage,
});

export { routerInstance };

export type AllRoutesByPath = keyof FileRoutesByPath;

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof routerInstance;
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface RouteContext extends ReturnType<typeof makeRoutingContext> {}
}
