import { Flagsmith } from '@mint-lib/flags';
import {
  FlagsmithProviders,
  type FlagsmithProviderProps,
} from '@mint-lib/flags-envs';

import { type MintAdminServiceLocatorAbstractFactoryContext } from '../types.js';

export async function flagsmithAbstractFactory(
  ctx: MintAdminServiceLocatorAbstractFactoryContext,
  name: keyof typeof FlagsmithProviders,
) {
  const providerSettings: FlagsmithProviderProps = FlagsmithProviders[name];
  const client = new Flagsmith({
    name: name as string,
    environmentID: providerSettings.envID,
  });
  const datadogRum = await ctx.getInstance('datadog');
  await client.init({
    cacheFlags: true,
    // @ts-ignore TS2339: Property 'options' does not exist on type.
    ...(providerSettings.options ?? {}),
    datadogRum: {
      client: datadogRum,
      trackTraits: true,
    },
  });

  return client;
}
