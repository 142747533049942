import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth')({
  async beforeLoad({ context }) {
    console.log('beforeLoad/_auth');
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance('i18n', '@mint-admin/auth-login');
  },
});
