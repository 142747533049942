import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured')({
  async beforeLoad({ context, location }) {
    if (!(await context.isAuthenticated())) {
      console.log('redirecting to login from secured route');
      throw redirect({
        to: '/login',
        search: {
          next: location.pathname,
        },
      });
    }
    return context;
  },
  async loader({ context }) {
    await context.serviceLocator.getInstance(
      'i18n',
      '@mint-admin/secured-layout',
    );
    await context.serviceLocator.getInstance('http', 'defaultApi');
  },
});
