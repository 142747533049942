import { BaseAuthAdminService } from '@mint-lib/auth';

import type { MintAdminServiceLocatorAbstractFactoryContext } from '../types.js';
import { temporaryAdminBaseStorage } from './auth/adminBaseStorage.js';

export async function authFactory(
  ctx: MintAdminServiceLocatorAbstractFactoryContext,
): Promise<BaseAuthAdminService> {
  const guestApi = await ctx.getInstance('http', 'guestApi');
  // @ts-ignore TODO: Fix type when proper login will be added
  const auth = new BaseAuthAdminService(guestApi, temporaryAdminBaseStorage);
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'auth', ctx.eventBus),
  );
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'logout', ctx.eventBus),
  );
  return auth;
}
