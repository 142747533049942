import { datadogRum } from '@mint-lib/datadog';
import { FlagsmithProviders } from '@mint-lib/flags-envs';
import { i18nFactory, requestFactory } from '@mint-lib/routing-context';
import { ServiceLocator } from '@mint-lib/service-locator';

import type { FlagsmithClient } from '../../flags/src/FlagsmithClient.js';
import { authFactory } from './factories/auth.js';
import { flagsmithAbstractFactory } from './factories/flagsmith.js';
import { httpFactory } from './factories/http.js';
import type { MintAdminServices, RouterContext } from './types.js';

export function makeRoutingContext(): RouterContext {
  const serviceLocator = new ServiceLocator<MintAdminServices>(console);
  // @ts-ignore TODO: Fix type when proper login will be added
  serviceLocator.registerAbstractFactory('auth', authFactory);
  serviceLocator.registerInstance('datadog', datadogRum);
  serviceLocator.registerAbstractFactory('flagsmith', flagsmithAbstractFactory);
  // @ts-expect-error TS2345. This factory can be used with wrong types
  serviceLocator.registerAbstractFactory('i18n', i18nFactory);
  serviceLocator.registerAbstractFactory('http', httpFactory);
  // @ts-expect-error TS2345. This factory can be used with wrong types
  serviceLocator.registerAbstractFactory('request', requestFactory);

  async function isFlagEnabled(
    env: keyof typeof FlagsmithProviders,
    flagName: string,
  ) {
    return (await serviceLocator.getInstance('flagsmith', env)).hasFeature(
      env as string,
      flagName,
    );
  }

  async function isAuthenticated() {
    return (await serviceLocator.getInstance('auth')).isLoggedIn();
  }

  async function getGuardContext(flagsService?: FlagsmithClient) {
    return {
      authService: await serviceLocator.getInstance('auth'),
      flagsService:
        flagsService ||
        (await serviceLocator.getInstance('flagsmith', '@mint/app')),
    };
  }

  async function load<T>(
    url: string,
    params?: Record<string, string | number>,
    type: 'guestApi' | 'defaultApi' = 'defaultApi',
  ): Promise<T> {
    return serviceLocator.getInstance('request', url, params, type) as T;
  }

  return {
    isFlagEnabled,
    isAuthenticated,
    getGuardContext,
    load,
    serviceLocator,
  };
}
