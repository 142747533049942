import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured/taxonomy')({
  async loader({ context }) {
    await context.serviceLocator.getInstance(
      'i18n',
      '@mint-admin/secured-layout-mdm',
    );
    await context.serviceLocator.getInstance('i18n', '@mint-lib/mdm');
  },
});
