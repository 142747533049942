import { createRootRoute, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';

import {
  InternalServerErrorPage,
  NotFoundErrorPage,
} from '@mint-lib/error-boundary';

import {
  FlagsmithDevTools,
  TanStackRouterDevtools,
} from '../components/DevTools.js';

export const Route = createRootRoute({
  component: Root,
  errorComponent: InternalServerErrorPage,
  notFoundComponent: NotFoundErrorPage,
});

function Root() {
  return (
    <>
      <Outlet />
      <Suspense fallback={null}>
        <TanStackRouterDevtools />
        <FlagsmithDevTools />
      </Suspense>
    </>
  );
}
